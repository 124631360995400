export const translationsEn = {
	home: "Home",
	about: "About",
	event: "Event",
	community: "News",
	faq: "FAQs",
	search: "Search",
	banner1: "It’s free for everyone. Download now and find your 360.",
	banner:
		"From fitness to nutrition, community to mood, Abu Dhabi 360 helps you live a healthier, happier life. Share challenges with your friends and become the best version of yourself, all with a single app.",
	need: "Why do you need",
	ad360: "Abu Dhabi 360?",
	why: "Why?",
	gym: "Whether you’re in the gym every night or have never picked up a weight; whether you eat all the right things or struggle with your diet; whether you’re naturally laid back or dealing with stress, Abu Dhabi 360 can help you find your best self.",
	download:
		"Download the app today and discover great workouts, delicious meals and ways to look after your mental health, all brought to you by leading experts. It’s a 360 view of your wellbeing that you can have with you day and night.",
	fitnessDes: `Access a huge range of workouts for all levels, free for you to try out at the gym, outside or at home, regardless of age or experience.<span class='text-decoration-underline text-green d-block'>Learn more.</span>`,
	nutriDes:
		`Share your food preferences and we’ll give you our latest healthy recipes, with simple, step-by-step instructions for making them at home.<span class='text-decoration-underline text-green d-block'>Learn more.</span>`,
	healthDes:
		`Use our daily mood tracker to monitor your state of mind, and learn techniques for coping with the stresses of everyday life.<span class='text-decoration-underline text-green d-block'>Learn more.</span>`,
	challengeDes:
		"Go up against your friends and family in our fun fitness challenges or take on a sporting icon in a unique test of speed, skill or endurance",
	eventDes:
		"Tell us the sports you love and we’ll fill your calendar with unmissable events.",
	exlusive: "EXCLUSIVE",
	content: "CONTENT",
	partners: "PARTNERS",
	challenge: "Challenges",
	event: "Events",
	special: `Tools to find <span className="text-stroke-green">your 360</span>`,
	special2: `Special <br /> <span className="text-stroke-green">features</span>`,

	highlight: `
                Whole-person
                <br />
                <span className="text-stroke-green">wellness</span>
              `,
	fitness: "Fitness",
	nutri: "Nutrition",
	health: "Mood",
	searchFor: "Search for events",
	filter: "Filter",
	faqText: "Frequently asked questions",
	mediakit: "Media kit",
	contact: "Contact",
	privacy: "Privacy Policy",
	follow: "Follow us",
	app: "App",
	readMore: "Read more",
	readLess: "Read less",
	selectRange: "Select by date range",

	our: "Our",
	mission: `Our <span className="text-stroke-green">mission</span>`,
	genuine: "A place of true balance.",
	missionDes:
		"Inspire and enable healthy living: (re)-building a sense of community and creating a balanced lifestyle among Abu Dhabi citizens through sport.",
	purpose: ` Our
  <span className="text-stroke-green">purpose</span>`,
	purposeDes: "We exist to improve the health & wellbeing of our community.",
	vision: `Our
  <span className="text-stroke-green">vision</span>`,
	visionDes:
		"To make Abu Dhabi the world’s healthiest, happiest and strongest society to live in and visit by 2030.",
	values: `Our <span className="text-stroke-green"> Values</span>`,
	vLead: "Leadership",
	vIns: "Inspiration",
	vEnd: "Endurance",
	vPlay: "Playfulness",
	vArch: "Achievement",
	vEmp: "Empowerment",
	mediaForm: ` Media
  <span className="text-stroke-green ps-0">kit</span>`,
	downMedia:
		"The purpose of our Media Kit is to drive consistency and clarity when implementing the visual language, and understanding how to apply the brand in the most effective manner possible.",
	fname: "First name",
	lname: "Last name",
	cname: "Company name",
	emailAddr: "Email address",
	mediaDownload: "Download media kit",
	contactAd:
		"For more information on Abu Dhabi 360 and media assets, please contact Abu Dhabi 360 support@myabudhabi360.ae",
	enqCon: "Contact & enquiries",
	rewards: "Rewards",
	rewardsDes:
		"Earn points by finding your 360 and be rewarded through prizes and experiences.",
	register: "Register",
	submit: "Submit",
	vendors: "Select vendor",
	registerEvent: "Join event",
	addMoreLinks: `Learn more at <a className="a-tag-lin" target="_blank" href="https://myabudhabi360.ae">https://myabudhabi360.ae</a> or connect with us online: <a className="a-tag-lin">@MyAbuDhabi360</a>.`,
	goHome: "Go to home page",
	PageNot: "Page not found",
	urlInfo: "Please check your URL",
	downloadApp: `<h4>
  Download the app and<br />find <span className="text-green">your 360</span>
</h4>`,
	copyright: "© 2023, Abu Dhabi 360, All rights reserved.",
	paramDown:
		"Download the app today and discover great workouts, delicious meals and ways to look after your mental health, all brought to you by leading experts. It’s a 360 view of your wellbeing that you can have with you day and night.",
	appDown: "Download the App",
	communityText:"Community",
	communityContent:`Use our daily mood tracker to monitor your state of mind, and learn techniques for coping with the stresses of everyday life.<span class='text-decoration-underline text-green d-block'>Learn more.</span>`,
	fitnessPara:"This is your fitness section! Here you’ll be able to find daily workouts aimed at helping you achieve your fitness goals through fitness tips and daily workouts! You’re able to select videos based on the equipment you have available to you, the difficulty of workout, and type of training you’d like to do that day!",
	fitnessHeading:`Modify <span className="text-green">your workout Goals</span>`,
	fitnessSubPara:'Explore the variety of different types of workouts Abu Dhabi 360 has to offer, whether you’re stiff and may need a yoga session, or you’ve got a good nights sleep and want to do a cardio session, we have it all!',
	NutritionBanner:'This is your nutrition section! Browse through our vast selection of tasty, healthy recipes with step by step instructions on how to make each one! These meals are perfect for both you, and a group of people, and the best part is you get a guilt-free, delicious meal!',
	NutritionHeadingOne:`Log <span className="text-green">your daily intake</span>`,
	NutritionHeadingOnePara:'This is our calorie counter. This tool allows you to monitor your nutrition through logging your meals throughout the day, giving you the macronutrient breakdown for each one allowing you to see exactly what you’re eating! You can use the calorie counter tool to make healthier decisions when it comes to meal time.',
	NutritionHeadingTwo:`Calorie <span className="text-green">Counter</span>`,
	NutritionHeadingTwoPara:'Track your nutrition with ease using our innovative calorie counter. Search any food you eat through the day and our database provides detailed macro and micronutrient information per serving. Monitoring your calorie and nutrient intake has never been simpler with the Abu Dhabi 360 calorie tracking tool at your fingertips.',
	MoodBanner:'Abu Dhabi 360 understands how important your mental health is, and we are here to help you through the ups and downs! Check out our mood section to see articles and videos on how to improve your overall mood and check in with yourself on a daily basis.',
	MoodHeadingOne:`Track <span class="text-green">your mood</span> with daily check-ins`,
	MoodHeadingOnePara:'Every two weeks, we will ask you to do a mood check-in to see how you are doing. This is to help personalise the app towards each user, providing special support when needed.',
	MoodHeadingTwoPara:'Daily check-ins can be conducted by you to help self-reflect and utilise our free resources when needed.',
	CommunityBanner:'Abu Dhabi 360’s fun fitness challenges are a great way to live a 360 life. Browse through the community challenges section to see what’s new!',
	CommunityHeadingOne:`Create <span class="text-green">a Challenge`,
	CommunityHeadingOnePara:'Want to create your own challenge that friends and family can participate in? No problem! Choose between a variety of activities that you would like to compete against friends and family in and get active for that top spot on our leaderboard!',
	CommunityHeadingTwo:`Redeem your <span class="text-green">Rewards</span>`,
	CommunityHeadingTwoPara:'By implementing positive changes to your lifestyle, you’ll be able to earn points that you’re able to redeem from the biggest retailers in the UAE! You can earn points by completing workouts, competing in challenges, brushing up on your nutritional knowledge and much more!',
};
