import React, { useState, useEffect } from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Google from "../assets/images/google-play.png";
import Iphone from "../assets/images/iphone-app.png";
import Logo from "../assets/images/logo-main.png";
import BackTopArrow from "../assets/images/Back-to-top-arrow.svg";
import FooterBg from "../assets/images/footer-bg-img.jpg";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { setRtl } from "../services/globalStates/constants";
import { useTranslation, Trans } from "react-i18next";
import * as langRoutes from "../utils.js/langRoutes";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  RiFacebookCircleLine,
  RiInstagramLine,
  RiTwitterLine,
  RiYoutubeLine,
} from "react-icons/ri";
import { Col, Row } from "react-bootstrap";

const Footer = () => {
  const { t, i18n } = useTranslation();
  let location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const urlVal = window.location.pathname;
  const dirVal = useSelector((state) => state.constants.dir);

  useEffect(() => {
    document.body.dir = localStorage.getItem("dir");
  }, []);

  useEffect(() => {
    document
      .getElementsByTagName("head")[0]
      .getElementsByTagName("link")[0].href = window.location.href;

    document
      .getElementsByTagName("head")[0]
      .getElementsByTagName("link")[2].href =
      dirVal === "rtl"
        ? window.location.origin + "/ar"
        : window.location.origin + "/en";
  }, [location]);

  const [visible, setVisible] = useState(false);

  const onChange = (event) => {
    i18n.changeLanguage(event.target.value); //Language Switcher

    localStorage.setItem("lng", event.target.value); //Store language switch value store in local storage
    localStorage.setItem("dir", i18n.dir());
    dispatch(setRtl(i18n.dir()));
    document.body.dir = i18n.dir();
    document.documentElement.lang = i18n.dir() === "rtl" ? "ar" : "en";
    document
      .getElementsByTagName("head")[0]
      .getElementsByTagName("link")[0].hreflang =
      i18n.dir() === "rtl" ? "ar-ae" : "en-us";
    setToggle(false);
  };

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (dirVal === "rtl" && urlVal === langRoutes.ABOUT_EN) {
      navigate(langRoutes.ABOUT_AR);
    } else if (dirVal === "ltr" && urlVal === langRoutes.ABOUT_AR) {
      navigate(langRoutes.ABOUT_EN);
    } else if (dirVal === "rtl" && urlVal === langRoutes.HOME_EN) {
      navigate(langRoutes.HOME_AR);
    } else if (dirVal === "ltr" && urlVal === langRoutes.HOME_AR) {
      navigate(langRoutes.HOME_EN);
    } else if (dirVal === "rtl" && urlVal === langRoutes.EVENT_EN) {
      navigate(langRoutes.EVENT_AR);
    } else if (dirVal === "ltr" && urlVal === langRoutes.EVENT_AR) {
      navigate(langRoutes.EVENT_EN);
    } else if (dirVal === "rtl" && urlVal === langRoutes.FAQ_EN) {
      navigate(langRoutes.FAQ_AR);
    } else if (dirVal === "ltr" && urlVal === langRoutes.FAQ_AR) {
      navigate(langRoutes.FAQ_EN);
    }
  }, [dirVal]);

  window.addEventListener("scroll", toggleVisible);
  return (
    <footer>
      <div className="container">
        <Row className="align-items-center mb-4">
          <Col lg={3} xs={12} className="order-lg-1 order-1">
            <div className="footer-logo">
              <Link to="/en/" onClick={() => window.scrollTo(0, 0)}>
                <img src={Logo} alt="logo" className="img-fluid" />
              </Link>
            </div>
          </Col>
          <Col lg={7} xs={12} className="order-lg-2 order-3">
            <Nav defaultActiveKey="/#" as="ul" className="footer-nav-link">
              <Nav.Item as="li">
                <NavLink
                  className="nav-link"
                  to={
                    dirVal === "rtl"
                      ? langRoutes.CONTACT_AR
                      : langRoutes.CONTACT_EN
                  }
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("contact")}
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <NavLink
                  className="nav-link"
                  eventKey="link-1"
                  to={
                    dirVal === "rtl" ? langRoutes.EVENT_AR : langRoutes.EVENT_EN
                  }
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("event")}
                </NavLink>
              </Nav.Item>
              {/* <Nav.Item as="li">
                <NavLink className="nav-link" eventKey="link-2">
                  {t("app")}
                </NavLink>
              </Nav.Item> */}
              <Nav.Item as="li">
                <NavLink
                  className="nav-link"
                  eventKey="link-2"
                  to={
                    dirVal === "rtl" ? langRoutes.MEDIA_AR : langRoutes.MEDIA_EN
                  }
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("mediakit")}
                </NavLink>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={2} xs={12} className="order-lg-3 order-2">
            <div
              className="btn-group select-language"
              role="group"
              aria-label="select-language"
            >
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="en"
                autoComplete="off"
                value="en"
                onChange={onChange}
                defaultChecked={dirVal === "ltr" ? true : false}
              />
              <label
                className={` btn ${dirVal === "ltr" ? "select2active" : ""}`}
                htmlFor="en"
              >
                ENG
              </label>
              <input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="ar"
                autoComplete="off"
                value="ar"
                onChange={onChange}
                defaultChecked={dirVal === "rtl" ? true : false}
              />
              <label
                className={` btn ${dirVal === "rtl" ? "select2active" : ""}`}
                htmlFor="ar"
              >
                العربية
              </label>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={3} xs={12}>
            <div className="footer-social-link">
              <a href="https://www.facebook.com/My-Abu-Dhabi-360-101715112748809">
                <RiFacebookCircleLine />
              </a>
              <a href="https://www.instagram.com/myabudhabi360/">
                <RiInstagramLine />
              </a>
              <a href="https://twitter.com/MyAbuDhabi360" target="_blank">
                <RiTwitterLine />
              </a>
              <a href="https://www.youtube.com/@myabudhabi360/featured">
                <RiYoutubeLine />
              </a>
            </div>
          </Col>
          <Col lg={3} xs={12}>
            <ul className="privacy-term-link-ul mb-0">
              <li>
                <Link
                  to={`${dirVal === "rtl" ? "/ar/privacy-policy" : "/en/privacy-policy"}`}
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {t("privacy")}
                </Link>
              </li>
              {/* <li>
                <Link>Terms & Conditions</Link>
              </li> */}
            </ul>
          </Col>
          {/* <Col lg={3} xs={4}>
            <div className="home-banner__app mt-0">
              <a
                href="https://apps.apple.com/us/app/abu-dhabi-360/id6444888839"
                target="_blank"
              >
                <img src={Iphone} alt="apple pay" className="full-contain" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.adsc.abudhabi360"
                target="_blank"
              >
                <img src={Google} alt="google pay" className="full-contain" />
              </a>
            </div>
          </Col> */}
          <Col lg={6} xs={12}>
            <p className="all-right-resvered mb-0">
              {t("copyright")}
            </p>
          </Col>
        </Row>
        <div className="back-to-top-arrow" style={{ visibility: "hidden" }}>
          <button className="back-to-top-arrow">
            <img src={BackTopArrow} alt="back-to-top" onClick={scrollToTop} />
          </button>
        </div>
      </div>
    </footer>
    // <footer>
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-xl-3 col-md-3 col-6 order-xl-0 order-1">
    //         <div className="footer-menu">
    //           <Link
    //             to={
    //               dirVal === "rtl" ? langRoutes.EVENT_AR : langRoutes.EVENT_EN
    //             }
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("event")}
    //           </Link>
    //         </div>
    //       </div>
    //       <div className="col-xl-3 col-md-3 col-6 order-xl-2 order-0">
    //         <div className="footer-menu">
    //           <Link
    //             to={dirVal === "rtl" ? langRoutes.HOME_AR : langRoutes.HOME_EN}
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("home")}
    //           </Link>
    //           <Link
    //             to={
    //               dirVal === "rtl"
    //                 ? langRoutes.CONTACT_AR
    //                 : langRoutes.CONTACT_EN
    //             }
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("contact")}
    //           </Link>
    //           {/* <Link
    //             to="/privacy-policy"
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("privacy")}
    //           </Link> */}
    //         </div>
    //       </div>
    //       <div className="col-xl-3 col-md-3 col-6 order-xl-3 order-3">
    //         <div className="footer-menu">
    //           <Link
    //             to={
    //               dirVal === "rtl" ? langRoutes.MEDIA_AR : langRoutes.MEDIA_EN
    //             }
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("mediakit")}
    //           </Link>
    //         </div>
    //       </div>
    //       <div className="col-xl-3 col-md-3 col-6 order-xl-4 order-4">
    //         <div className="footer-menu">
    //           <Link
    //             to={
    //               dirVal === "rtl"
    //                 ? langRoutes.PRIVACY_AR
    //                 : langRoutes.PRIVACY_EN
    //             }
    //             className="footer-link"
    //             onClick={() => window.scrollTo(0, 0)}
    //           >
    //             {t("privacy")}
    //           </Link>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row mt-xl-5 mt-3 pt-xl-4 pt-3">
    //       <div className="col-md-3 col-4 d-md-flex d-none">
    //         <div className="footer-logo">
    //           <Link to="/en/" onClick={() => window.scrollTo(0, 0)}>
    //             <img src={Logo} alt="logo" className="full-contain" />
    //           </Link>
    //         </div>
    //       </div>
    //       <div className="col-md col-12">
    //         <h4 className="footer-head d-md-block d-inline-block">
    //           {t("app")}
    //         </h4>
    //         <div className="home-banner__app mt-3 d-xl-block d-inline-block">
    //           <a
    //             href="https://apps.apple.com/us/app/abu-dhabi-360/id6444888839"
    //             target="_blank"
    //           >
    //             <img src={Iphone} alt="apple pay" className="full-contain" />
    //           </a>
    //           <a
    //             href="https://play.google.com/store/apps/details?id=com.adsc.abudhabi360"
    //             target="_blank"
    //           >
    //             <img src={Google} alt="google pay" className="full-contain" />
    //           </a>
    //         </div>
    //       </div>
    //       <div className="col-md col-12 mt-xl-0 mt-4">
    //         <h4 className="footer-head">{t("follow")}</h4>
    //         <div className="social-links mt-3">
    //           <a
    //             href="https://www.facebook.com/My-Abu-Dhabi-360-101715112748809"
    //             target="_blank"
    //           >
    //             <FaFacebookF />
    //           </a>
    //           <a
    //             href="https://www.instagram.com/myabudhabi360/"
    //             target="_blank"
    //           >
    //             <FaInstagram />
    //           </a>
    //           <a href="https://twitter.com/MyAbuDhabi360" target="_blank">
    //             <FaTwitter />
    //           </a>
    //           <a
    //             href="https://www.youtube.com/@myabudhabi360/featured"
    //             target="_blank"
    //           >
    //             <FaYoutube />
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="back-to-top-arrow" style={{ visibility: "hidden" }}>
    //       <Button className="back-to-top-arrow">
    //         <img src={BackTopArrow} alt="back-to-top" onClick={scrollToTop} />
    //       </Button>
    //     </div>
    //   </div>
    // </footer>
  );
};

export default Footer;
