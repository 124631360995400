import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const adApis = createApi({
  reducerPath: "ad360Apis",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
  }),
  refetchOnMountOrArgChange: 0.001,
  keepUnusedDataFor: 0.000001,
  skip: true,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: (data) =>
        `/web/listAllEvent?page=${data.page}&search=${data.search}&lang=${data.lang}&from=${data.from}&to=${data.to}`,
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 0.000001,
    }),
    getAllCommunities: builder.query({
      query: (data) => `/web/news`,
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 0.000001,
    }),
    getSigleNews: builder.query({
      query: (data) => `/web/newsDetail?_id=${data}`,
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 0.000001,
    }),
    registerEvent: builder.mutation({
      query: (data) => ({
        url: "/web/registereEvent",
        method: "POST",
        body: data,
      }),
    }),
    privacy: builder.query({
      query: (data) => `/web/getPrivacyPolicy?lang=${data}`,
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 0.000001,
    }),
    faq: builder.query({
      query: (data) => `/web/faq?lang=${data}`,
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 0.000001,
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/user/login",
        method: "POST",
        body: data,
      }),
    }),
    mediakit: builder.mutation({
      query: (data) => ({
        url: "/web/mediaKit",
        method: "POST",
        body: data,
      }),
    }),
    enquiry: builder.mutation({
      query: (data) => ({
        url: "/web/enquiry",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useGetAllEventsQuery,
  useGetAllCommunitiesQuery,
  useLoginMutation,
  useEnquiryMutation,
  useMediakitMutation,
  usePrivacyQuery,
  useFaqQuery,
  useGetSigleNewsQuery,
  useRegisterEventMutation,
} = adApis;
