import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo-main.png";
import LogoSecond from "../assets/images/Logo.png";
import LogoThird from "../assets/images/ad-icon.png";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap/";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { i18next } from "../utils.js/i18next";
import { useDispatch } from "react-redux";
import { setRtl } from "../services/globalStates/constants";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as langRoutes from "../utils.js/langRoutes";
i18next(); //Calling i18Next configuration function

const Header = () => {
	const dispatch = useDispatch();
	let location = useLocation();
	const navigate = useNavigate();
	const urlVal = window.location.pathname;
	const Active = ({ isActive }) => "nav-link" + (isActive ? " active" : "");
	const { t, i18n } = useTranslation();
	const dirVal = useSelector((state) => state.constants.dir);
	const langRoute = dirVal === "rtl" ? "ar" : "en";

	const [toggle, setToggle] = useState(false);

	useEffect(() => {
		document.body.dir = localStorage.getItem("dir");
	}, []);

	useEffect(() => {
		document
			.getElementsByTagName("head")[0]
			.getElementsByTagName("link")[0].href = window.location.href;

		document
			.getElementsByTagName("head")[0]
			.getElementsByTagName("link")[2].href =
			dirVal === "rtl"
				? window.location.origin + "/ar"
				: window.location.origin + "/en";
	}, [location]);

	useEffect(() => {
		if (dirVal === "rtl" && urlVal === langRoutes.MEDIA_EN) {
			navigate(langRoutes.MEDIA_AR);
		} else if (dirVal === "ltr" && urlVal === langRoutes.MEDIA_AR) {
			navigate(langRoutes.MEDIA_EN);
		} else if (dirVal === "rtl" && urlVal === langRoutes.HOME_EN) {
			navigate(langRoutes.HOME_AR);
		} else if (dirVal === "ltr" && urlVal === langRoutes.HOME_AR) {
			navigate(langRoutes.HOME_EN);
		} else if (dirVal === "rtl" && urlVal === langRoutes.EVENT_EN) {
			navigate(langRoutes.EVENT_AR);
		} else if (dirVal === "ltr" && urlVal === langRoutes.EVENT_AR) {
			navigate(langRoutes.EVENT_EN);
		} else if (dirVal === "rtl" && urlVal === langRoutes.CONTACT_EN) {
			navigate(langRoutes.CONTACT_AR);
		} else if (dirVal === "ltr" && urlVal === langRoutes.CONTACT_AR) {
			navigate(langRoutes.CONTACT_EN);
		} else if (dirVal === "rtl" && urlVal === langRoutes.PRIVACY_EN) {
			navigate(langRoutes.PRIVACY_AR);
		} else if (dirVal === "ltr" && urlVal === langRoutes.PRIVACY_AR) {
			navigate(langRoutes.PRIVACY_EN);
		}
	}, [dirVal]);

	const onChange = (event) => {
		i18n.changeLanguage(event.target.value); //Language Switcher

		localStorage.setItem("lng", event.target.value); //Store language switch value store in local storage
		localStorage.setItem("dir", i18n.dir());
		dispatch(setRtl(i18n.dir()));
		document.body.dir = i18n.dir();
		document.documentElement.lang = i18n.dir() === "rtl" ? "ar" : "en";
		document
			.getElementsByTagName("head")[0]
			.getElementsByTagName("link")[0].hreflang =
			i18n.dir() === "rtl" ? "ar-ae" : "en-us";
		setToggle(false);
	};

	const onChangeMob = (event) => {
		i18n.changeLanguage(event.target.value); //Language Switcher
		localStorage.setItem("lng", event.target.value); //Store language switch value store in local storage
		localStorage.setItem("dir", i18n.dir());
		dispatch(setRtl(i18n.dir()));
		document.body.dir = i18n.dir(); //Implementation of ARABIC RTL version
	};

	const handleLinksNav = () => {
		window.scrollTo(0, 0);
		setToggle(false);
	};

	return (
		<>
			<header id="header">
				<Navbar
					key="lg"
					bg="light"
					expand="lg"
					expanded={toggle}
					className="mb-3"
					fixed="top"
					navbar-collapse
				>
					<Container fluid="lg">
						<Navbar.Brand href="/en/">
							<img src={Logo} alt="logo" className="full-contain" />
							{/* <img
                src={LogoThird}
                alt="logo"
                className="full-contain d-lg-none d-block w-50 ms-auto"
              /> */}
						</Navbar.Brand>

						<Navbar.Toggle onClick={() => setToggle(true)} />
						<Navbar.Offcanvas
							id="offcanvasNavbar-expand-lg"
							aria-labelledby="offcanvasNavbar-expand-lg"
							placement="end"
						>
							<Offcanvas.Header>
								<img
									src={LogoSecond}
									className="full-contain"
									alt="Mobile logo"
									style={{
										height: "120px",
										width: "120px",
										objectFit: "contain",
										margin: "auto",
									}}
								/>
							</Offcanvas.Header>
							<Offcanvas.Body>
								<Nav className="flex-grow-1 pe-3">
									<NavLink
										className={Active}
										to={
											dirVal === "rtl" ? langRoutes.HOME_AR : langRoutes.HOME_EN
										}
										onClick={handleLinksNav}
									>
										{t("home")}
									</NavLink>
									<NavLink
										className={Active}
										to={
											dirVal === "rtl"
												? langRoutes.ABOUT_AR
												: langRoutes.ABOUT_EN
										}
										onClick={handleLinksNav}
									>
										{t("about")}
									</NavLink>
									<NavLink
										className={Active}
										to={
											dirVal === "rtl"
												? langRoutes.EVENT_AR
												: langRoutes.EVENT_EN
										}
										onClick={handleLinksNav}
									>
										{t("event")}
									</NavLink>
									{/* <NavLink className={Active} to="/community">
                    {t("community")}
                  </NavLink> */}
									<NavLink
										className={Active}
										to={
											dirVal === "rtl" ? langRoutes.FAQ_AR : langRoutes.FAQ_EN
										}
										onClick={handleLinksNav}
									>
										{t("faq")}
									</NavLink>
								</Nav>
								<div className="text-center mobile-login-wrap">
									{/* <a className="btn btn-primary mobile-login">Login</a> */}
								</div>
								<div
									style={{ display: "flex", justifyContent: "center" }}
								></div>
								<a
									className="btn btn-primary btn-sm-18"
									target="_blank"
									href="https://apps.apple.com/us/app/abu-dhabi-360/id6444888839"
								>
									{t("appDown")}
								</a>
								<div
									className="close-text d-lg-none d-md-block text-center mt-5"
									style={{ marginTop: "4em" }}
								>
									<a className="mobile-close" onClick={() => setToggle(false)}>
										Close
									</a>
								</div>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

export default Header;
