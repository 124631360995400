import { createSlice } from "@reduxjs/toolkit";

export const getConstants = createSlice({
  name: "constants",
  initialState: {
    dir: "ltr"
  },
  reducers: {
    setRtl: (state, action) => {
      state.dir = action.payload;
    },
  },
});

export const { setWalletInfo, setRtl } = getConstants.actions;

export default getConstants.reducer;
