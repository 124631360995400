import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { useEffect } from "react";
import "./App.css";
import Loader from "./utils.js/loader";

import { useLocation, useNavigate } from "react-router-dom";

const Footer = React.lazy(() => import("./Components/Footer"));
const Header = React.lazy(() => import("./Components/Header"));
const About = React.lazy(() => import("./Pages/About"));
// const Community = React.lazy(() => import("./Pages/Community"));
const Congrates = React.lazy(() => import("./Pages/Congrates"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const EventRegister = React.lazy(() => import("./Pages/EventRegister"));
const Events = React.lazy(() => import("./Pages/Events"));
const Faq = React.lazy(() => import("./Pages/Faq"));
const Home = React.lazy(() => import("./Pages/Home"));
const MediaKit = React.lazy(() => import("./Pages/MediaKit"));
const Signin = React.lazy(() => import("./Pages/Signin"));
const Signup = React.lazy(() => import("./Pages/Signup"));
const Tickets = React.lazy(() => import("./Pages/Tickets"));
const Privacy = React.lazy(() => import("./Pages/Privacy"));
const CommunityInner = React.lazy(() => import("./Pages/CommunityInner"));
const Register = React.lazy(() => import("./Pages/Register"));
const PageNotFound = React.lazy(() => import("./Pages/PageNotFound"));
const Fitness = React.lazy(() => import("./Pages/Fitness"));
const Nutrition = React.lazy(() => import("./Pages/Nutrition"));
const Mood = React.lazy(() => import("./Pages/Mood"));
const Community = React.lazy(() => import("./Pages/Communityy"));
function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.location.replace(window.location.href + "en/");
    }
  }, []);

  useEffect(() => {
    let url = window.location.pathname;

    if (!url.match(/\/$/)) {
      let newUrl = (url += "/");
      window.location.replace(newUrl);
    }
  }, []);

  console.log("UUUU", window.ttq.page);

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              width: "100%",
              display: "grid",
              placeItems: "center",
              height: "100vh",
            }}
          >
            <Loader />
          </div>
        }
      >
        <Header />
        <Routes>
          <Route path="/:lang" element={<Home />} />
          <Route path="/:lang/about" element={<About />} />
          <Route path="/register/:id" element={<Register />} />
          <Route path="/:lang/events" element={<Events />} />
          <Route path="/:lang/community" element={<Community />} />
          <Route path="/community-details/:id" element={<CommunityInner />} />
          <Route path="/:lang/faq" element={<Faq />} />
          <Route path="/register" element={<EventRegister />} />
          <Route path="/congrates" element={<Congrates />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/:lang/media-kit" element={<MediaKit />} />
          <Route path="/:lang/contact" element={<Contact />} />
          <Route path="/:lang/privacy-policy" element={<Privacy />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/:lang/fitness" element={<Fitness />} />
          <Route path="/:lang/nutrition" element={<Nutrition />} />
          <Route path="/:lang/mood" element={<Mood />} />          
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
}

export default App;
