export const HOME_EN = "/en/"
export const HOME_AR = "/ar/"

export const ABOUT_EN = "/en/about/"
export const ABOUT_AR = "/ar/about/"

export const EVENT_EN = "/en/events/"
export const EVENT_AR = "/ar/events/"

export const FAQ_EN = "/en/faq/"
export const FAQ_AR = "/ar/faq/"

export const MEDIA_EN = "/en/media-kit/"
export const MEDIA_AR = "/ar/media-kit/"

export const CONTACT_EN = "/en/contact/"
export const CONTACT_AR = "/ar/contact/"

export const PRIVACY_EN = "/en/privacy-policy/"
export const PRIVACY_AR = "/ar/privacy-policy/"
