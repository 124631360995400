import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationsAr } from "../services/lang/ar";
import { translationsEn } from "../services/lang/en";

export const i18next = () => {
  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: translationsEn },
      ar: { translation: translationsAr },
    },
    lng: localStorage.getItem("lng") || "en",
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
};
