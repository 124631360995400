export const translationsAr = {
	home: "الصفحة الرئيسية",
	about: "حول",
	event: "حدث",
	community: "الأخبار",
	faq: "التعليمات",
	search: "بحث",
	banner1:
		"تطبيق مجاني للجميع. قم بتنزيل التطبيق الآن واكتشف Abu Dhabi 360 الخاص بك.",
	banner:
		"من اللياقة البدنية إلى التغذية، المزاج إلى المجتمع يساعدك تطبيق أبوظبي 360 على عيش حياة أكثر صحة وسعادة. شارك التحديات مع أصدقائك، واكسب المكافآت لتبادل التجارب الفريدة، وأظهر أفضل ما لديك، وكل ذلك في تطبيقِ واحد.",
	need: "لماذا تحتاج إلى تطبيق",
	ad360: "أبو ظبي 360",
	gym: "سواء كنت من رواد صالات الألعاب الرياضية كل مساء أو لم ترفع أثقال في حياتك؛ سواء كنت تتناول غذاءً صحياً أو تعاني من نظامك الغذائي؛ سواء كنت تعيش أسلوب حياة متسم بالاسترخاء أو تعاني من الإجهاد، يمكن لتطبيق أبوظبي 360 مساعدتك في إظهار أفضل ما لديك.",
	download:
		"قم بتنزيل التطبيق اليوم واكتشف كل ما يقدمه لك كبار الخبراء من تدريبات رائعة، ووجبات لذيذة وطرق  للعناية بصحتك النفسية. 360 درجة من الرؤية الشاملة معك ليلًا ونهارًا للحفاظ على رفاهيتك.",
	fitnessDes:
		"يمكنك الوصول إلى مجموعة كبيرة من التدريبات المخصصة لكافة المستويات، ولك كامل الحرية في أن تجربها في صالة الألعاب الرياضية، أو في الخارج أو في المنزل، بغض النظر عن عمرك أو خبرتك.<span class='text-decoration-underline text-green d-block'>يتعلم أكثر</span>",
	nutriDes:
		"شاركنا تفضيلاتك الغذائية، وسنقدم لك أحدث وصفاتنا الصحية، مع تعليمات سهلة ومفصّلة خطوة بخطوة لتحضيرها في المنزل.<span class='text-decoration-underline text-green d-block'>يتعلم أكثر</span>",
	healthDes:
		"واجه أصدقائك وعائلتك في تحديات اللياقة البدنية الممتعة لدينا أو واجه أيقونة رياضية في اختبار فريد للسرعة أو المهارة أو القدرة على التحمل.<span class='text-decoration-underline text-green d-block'>يتعلم أكثر</span>",
	challengeDes:
		"واجه أصدقائك وعائلتك في تحديات اللياقة البدنية الممتعة لدينا أو واجه أيقونة رياضية في اختبار فريد للسرعة أو المهارة أو القدرة على التحمل",
	eventDes: "أخبرنا بالرياضات التي تحبها وسنملأ جدولك بأحداث لا تُفوَّت",
	exclusive: "حصرية",
	content: "المحتوى",
	why: "لماذا?",
	partners: "الشركاء",
	challenge: "تحديات",
	event: "فعاليات",
	special: ` مميزات <span className="text-stroke-green">خاصة</span>`,
	special2: ` مميزات <br /><span className="text-stroke-green">خاصة</span>`,

	highlight: `  أبرز <br /> <span className="text-stroke-green">النقاط </span>    `,
	fitness: "اللياقة",
	nutri: "التغذية",
	health: "المزاج",
	searchFor: "البحث عن فعالية",
	filter: "تصفية",
	faqText: "أسئلة مكررة",
	mediakit: " مجموعة الوسائط",
	contact: "اتصل بنا",
	privacy: "الخصوصية",
	follow: "تابعنا",
	app: "التطبيق",
	readMore: "اقرأ أكثر",
	readLess: "أقرأ أقل",
	selectRange: "حدد حسب النطاق الزمني",

	our: "Our",
	mission: `<span className="text-stroke-green">مهمتنا </span>`,
	genuine: "مكان للتوازن الحقيقي.",
	missionDes:
		"أن تكون أبوظبي، بحلول العام 2030، المجتمع الأكثر صحة، وسعادة وقوة لزيارته والعيش فيه.",
	purpose: `<span className="text-stroke-green">هدفنا </span>`,
	purposeDes: " نحن نسعى لتحسين الصحة والرفاهية في مجتمعنا.",
	vision: `<span className="text-stroke-green">رؤيتنا </span>`,
	visionDes:
		"أن خلال تعزيز عالم الرياضة، ان تكون أبوظبي، بحلول العام 2030، المجتمع الأكثر صحة، وسعادة وقوة لزيارته والعيش فيه.",
	values: `<span className="text-stroke-green">قيمنا </span>`,
	vLead: "القيادة",
	vIns: "الإلهام",
	vEnd: "التحمل",
	vPlay: "المرح",
	vArch: "الإنجاز",
	vEmp: "التمكين",
	mediaForm: `<span className="text-stroke-green ps-0">مجموعة الوسائط</span>`,
	downMedia:
		"الغرض من مجموعة الوسائط الخاصة بنا هو تحقيق التناسق والوضوح عند تنفيذ اللغة المرئية، وفهم كيفية تطبيق العلامة التجارية بالطرق الأكثر فعالية.",
	fname: "الاسم الأول",
	lname: "الشهرة",
	cname: "اسم الشركة",
	emailAddr: "عنوان البريد الإلكتروني",
	mediaDownload: "تنزيل مجموعة الوسائط",
	contactAd:
		"لمزيد من المعلومات والمواد الإعلامية الخاصة بمبادرة أبوظبي 360، يرجى التواصل  على البريد الإلكتروني support@myabudhabi360.ae.",
	enqCon: "الاتصال والاستفسارات",
	rewards: "جوائز",
	rewardsDes: "اكتشف ال 360 الخاص بك و احصل على مكافآت تضمن الجوائز و التجارب",
	register: "سجل",
	submit: "يُقدِّم",
	vendors: "حدد البائع",
	registerEvent: "الانضمام إلى الحدث",
	addMoreLinks: ` اكتشفوا المزيد عن أبوظبي 360 الآن من خلال تحميل التطبيق عبر (<a className="a-tag-lin" target="_blank" href="https://myabudhabi360.ae">https://myabudhabi360.ae</a>)، ومتابعتنا عبر حساب MyAbuDhabi360@ على مواقع التواصل الاجتماعي.  `,
	goHome: "اذهب إلى الصفحة الرئيسية",
	PageNot: "الصفحة غير موجودة",
	urlInfo: "يرجى التحقق من عنوان URL الخاص بك",
	downloadApp: ` <h4>
  حمّّل تطبيق
  <span className="text-green">360 أبوظبي</span>
  <br />
  واستعد للنشاط والحركة
</h4>`,
	copyright: "© 2023, أبوظبي 360، كل الحقوق محفوظة.",
	paramDown:
		"قم بتنزيل التطبيق اليوم واكتشف التدريبات الرائعة والوجبات اللذيذة وطرق العناية بصحتك العقلية، كل ذلك يقدمه لك أفضل الخبراء. إنه عرض 360 درجة لرفاهيتك يمكن أن يكون معك ليلًا ونهارًا",
	appDown: "حمّل التطبيق",
	communityText:"المجتمع",
	communityContent:"استخدم متتبع المزاج اليومي الخاص بالتطبيق لمتابعة حالة صحتك النفسية وتعلم طرق مختلفة للتعامل مع ضغوط الحياة اليومية.<span class='text-decoration-underline text-green d-block'>يتعلم أكثر</span>",
	fitnessPara:"هذا هو قسم اللياقة الخاص بك! من هنا يمكنك العثور على تمارين مختلفة تهدف إلى مساعدتك في تحقيق أهداف لياقتك من خلال نصائح وتمارين يومية! يمكنك اختيار مقاطع الفيديو بناءً على الأدوات المتاحة لديك، وصعوبة التمرين الذي ترغب به، ونوع التدريب الذي ترغب في القيام به في ذلك اليوم!",
	fitnessHeading:`تعديل أهدافك <span className="text-green">للتمرين</span>`,
	fitnessSubPara:'اكتشف مجموعة متنوعة من التمارين التي يقدمها تطبيق أبوظبي 360، سواء كان جسمك يعاني من التيبس أو بحاجة إلى جلسة يوغا، أو كنت قد نمت جيدًا وترغب في تمرين كارديو للمزيد من النشاط والصحة، لدينا كل شيء تتمناه في رحلتك للياقة!',
	NutritionBanner:'تحديات اللياقة الممتعة في أبوظبي 360 هي وسيلة رائعة لتعيش حياة كاملة من الصحة واللياقة. تصفح قسم تحدياتالمجتمع لرؤية كل ما هو جديد',
	NutritionHeadingOne:`سعراتك<span className="text-green"> الحرارية اليومية </span>`,
	NutritionHeadingOnePara:'هذا هو عداد السعرات الحرارية الخاص بنا. هذه الأداة ستساعدك على مراقبة تغذيتك من خلال تسجيل وجباتك طوال اليوم، وتوفير كل التفاصيل حول المكونات الغذائية الرئيسية في كل وجبة ويسمح لك برؤية بالضبط ما تتناوله من سعرات! يمكنك استخدام عداد السعرات الحرارية لاتخاذ قرارات صحية أفضل عند تناول الطعام.',
	NutritionHeadingTwo:`عداد السعرات <span className="text-green"> الحرارية </span>`,
	NutritionHeadingTwoPara:'تتبع تغذيتك بسهولة باستخدام عداد السعرات الحرارية المبتكر لدينا. ابحث عن أي طعام تأكله خلال اليوم، وقاعدة البيانات لدينا ستوفر لك معلومات مفصلة حول السعرات الغذائية الرئيسية والفرعية لكل وجبة. معرفة استهلاك السعرات الحرارية والمواد الغذائية أصبح أسهل مع أداة تتبع السعرات الحرارية في أبوظبي 360 لاستخدامها في أي وقت تريد والحفاظ على نمط حياتك الصحي.',
	MoodBanner:'تطبيق أبوظبي 360 يدرك مدى أهمية صحتك النفسية والعقلية، ونحن هنا لمساعدتك في التغلب على تحديات الحياة المختلفة والتركيز على اللحظات الجيدة! اكتشف قسم المزاج لدينا لمزيد من المقالات ومقاطع الفيديو حول كيفية تحسين مزاجك العام وتتبع صحتك النفسية يومياً.',
	MoodHeadingOne:`تتبع مزاجك<span class="text-green"> مع الفحص </span> اليومي `,
	MoodHeadingOnePara:'كل أسبوعين، سنطلب من المستخدمين إجراء فحص للمزاج لمعرفة كيف يسير الأمر معهم، هذا الفحص يهدف إلى تخصيص التطبيق بحسب احتياجات كل مستخدم وتوفير الدعم الخاص عند الحاجة.',
	MoodHeadingTwoPara:'يمكن للمستخدمين إجراء فحوصات يومية لمساعدتهم على اكتشاف ذاتهم والاستفادة أيضًا من الموارد المجانية المتاحة في التطبيق.',
	CommunityBanner:'تحديات اللياقة الممتعة في أبوظبي 360 هي وسيلة رائعة لتعيش حياة كاملة من الصحة واللياقة. تصفح قسم تحديات المجتمع لرؤية كل ما هو جديد!',
	CommunityHeadingOne:`إنشاء <span class="text-green">تحدي جديد </span`,
	CommunityHeadingOnePara:'ترغب في إنشاء تحدي خاص يمكن للأصدقاء والعائلة المشاركة فيه والاستمتاع معًا كمجموعة؟ الأمر سهل، اختر بين مجموعة من الأنشطة التي ترغب في المنافسة بها مع الأصدقاء والعائلة، وابدأ في الحركة للحصول على المركز الأول في لوحة الترتيب لدينا!',
	CommunityHeadingTwo:`استرداد<span class="text-green"> الجوائز</span>`,
	CommunityHeadingTwoPara:'من خلال التغييرات الإيجابية الجديدة في نمط حياتك، ستتمكن من كسب نقاط يمكنك استبدالها في أكبر متاجر التجزئة في الإمارات العربية المتحدة! يمكنك كسب النقاط عن طريق إكمال التمارين الرياضية، والمشاركة في التحديات، وتحسين معرفتك عن التغذية، وأكثر من ذلك!',
};
