import React from "react";

import { Oval } from "react-loader-spinner";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Loader = () => {
  return (
    <>
      <Oval
        height={80}
        width={80}
        secondaryColor="#00e6b9"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        color="#5050b4"
        strokeWidth={3}
        strokeWidthSecondary={3}
      />
    </>
  );
};

export default Loader;
